.watchEventsContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.watchEventsButton {
  background: var(--main-gray);
  border: 1px solid var(--main-gray);
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-midnight-blue);
  font-weight: bold;
  text-decoration: none;
}

.watchEventsButton:hover {
  cursor: pointer;
  background: #cdd0d4;
}

.watchEventsButton:first-child {
  width: 85%;
}

.watchEventsButton:last-child {
  width: 25%;
  height: 48px;
}

.watchEventsVideoSettings {
  position: absolute;
  width: 38.9vw;
  top: 0;
  right: 0;
  background: #171f2f;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
}

.watchEventsVideoSettingsOffset {
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  justify-content: center;
  gap: 40px;
  margin-top: 10px;
}

.watchEventsVideoSettingsOffset > input {
  background: #ffffff;
  border: 0.3px solid rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  height: 31px;
}

.watchEventsVideoSettings > button {
  background: #171f2f;
  border: 1px solid #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}

.watchEventsVideoSettings > button:hover {
  background: #0f141f;
}

.watchEventsVideoSettingsOffsetCircle {
  border: 4px solid #08b839;
  border-radius: 50%;
}

.watchEventsVideoSettingsOffsetCircle > div {
  background: #ffffff;
  box-shadow: inset 1px 2px 1px -1px #5e5757, inset 1px 2px 1px -1px #5e5757,
    inset 2px 1px 4px -1px #000000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #313131;
}

@media screen and (max-width: 1700px) {
  .watchEventsButton {
    font-size: 11px;
    max-height: 48px;
  }

  .watchEventsButton:first-child {
    width: 100%;
  }
}

.watchEventsSettingsHeader > * {
  display: flex;
  justify-content: space-between;
}

.watchEventsSettingsHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pitchSettings {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.pitchSettings form {
  display: flex;
  gap: 20px;
}
