.MatchesData__title {
    background: #0F1521;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.25);
    
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
}