.PlayerMatchesData__content {
    display: flex;
    width: 100%;
    background-color: #0F1521;;
    align-items: center;
    border-bottom: 1px solid white;
}

.PlayerMatchesData__content:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7), inset 4px -1px 9px 4px #22E785;
}