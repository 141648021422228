.pageContainer {
  width: 100%;
  height: 100%;
  background-color: #2b333d;
  color: #fff;
  position: relative;
}

.pageContainer>h3 {
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  padding: 20px;
  width: calc(100% - 40px);
  background-color: #0f1521;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.25);
  margin: 0;
}

.pageContainer>p {
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: calc(100% - 40px);
}

.playersTablesContainer {
  display: flex;
  align-items: flex-start;
  gap: 3px;
  padding: 0 10px;
  overflow-y: auto;
  height: calc(100% - 150px);
}

.pageTabs {
  font-weight: 500;
  font-size: 17px;
  position: absolute;
  display: flex;
  gap: 3px;
  color: #fff;
  right: 100px;
  top: -52px;
}

.pageTabs>div,
.pageTabs>a {
  background: #0f1521;
  border: 1px solid #ffffff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  cursor: pointer;
  position: relative;
  top: 20px
}

@media screen and (max-width: 1900px) {
  .playersTablesContainer {
    flex-direction: column;
  }
}