.inputContainer {
  position: relative;
  margin: 0 10px;
}

.input {
  background: var(--main-navy-blue);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px;
  border-radius: 10px;
  color: var(--main-whitesmoke);
  min-width: 300px;
  border: none;
  padding: 10px;
  font-weight: 300;
  font-size: 18px;
}

.inputLight{
  background-image: url(../../../../public/images/glass-light.svg);
  box-shadow: none;
  background-color: #EBF1FF;
  color: black;
}

.input.focused {
  outline: none;
  background-image: none;
  border-radius: 10px 10px 0 0;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 3px;
  background-color: #162B37;
}

.tabs button {
  cursor: pointer;
  border: 1px solid var(--main-whitesmoke);
  border-radius: 10px;
  background-color: transparent;
  color: var(--main-whitesmoke);
  width: 189px;
  padding: 5px;
  font-weight: 400;
}

.tabs button.active {
  font-weight: 500;
  background: var(--main-sky-blue);
  border: 1px solid var(--main-sky-blue);
  color: var(--main-navy-blue);
}

.tabs button.activeLight {
  border: none;
  box-shadow: none;
  background: #EAD399 ;
  color: black;
}

.dropdown {
  display: none;
  margin: 0;
  position: absolute;
  left: 0;
  z-index: 1;
  min-height: 70px;
  height: fit-content;
  max-height: 500px;
  overflow-y: auto;
  background-color: var(--main-gray);
  border-radius: 0 0 10px 10px;
  list-style: none;
  width: 100%;
  color: var(--main-navy-blue);
  padding: 0;
}

.dropdownLight {
  box-shadow: none;
  background-color: #EBF1FF;
}

.dropdown.open {
  display: block;
}

.simpleListItem {
  padding: 10px 20px;
}

.simpleListItemLight {
  color: black;
}

.listItem {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  position: relative;
  color: var(--main-navy-blue);
  text-decoration: none;
}

.listItemLight {
  color: black;
}

.listItem:hover {
  font-weight: 500;
  background-color: var(--main-sky-blue);
  color: var(--main-navy-blue);
}

.listItemLight:hover {
  box-shadow: none;
  background-color: #EAD399;
}

/* .listItem:not(:last-child) {
  border-bottom: 1px solid #74777d;
} */

.listItem span {
  padding-left: 20px;
}


.listItem img {
  height: 40px;
  object-fit: contain;
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.more {
  padding: 15px;
  text-align: center;
  cursor: pointer;
}