.PlayerMatchesData__title {
    background-color: #0F1521;
    height: 50px;
    border-radius: 10px 10px 0px 0px;
    
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    color: white;
    position: relative;
    top: 10px;
}