.article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--main-navy-blue);
}

.leftContainer {
    display: flex;
}

.teamLogo {
    object-fit: contain;
    height: 100px;
    width: 100px;
}

.teamTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 41px;
    display: flex;
    align-items: center;
    margin-left: 137px;
    color: var(--main-whitesmoke);
    white-space: nowrap;
}

.rightContainer{
    display: flex;
}

.button {
    height: 100px;
    width: 289px;
    background-color: var(--main-navy-blue);
    border: none;
    color: var(--main-whitesmoke);
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 41px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover {
    background: var(--main-gray);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
