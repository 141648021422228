.videoCutsActionList {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: calc(70vh * var(--scale-multiplier));
  min-height: calc(70vh * var(--scale-multiplier));
  background: #616161;
  overflow-y: auto;
}
.cutsPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 10px 15px 10px 10px;
  background-color: #3c3c3c;
}
.cutsInfo {
  width: 250px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.videoCutsActionListItem {
  display: flex;
  align-items: center;
  background: #616161;
  box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 15px 10px 10px;
  width: -webkit-fill-available;
  min-width: 315px;
  gap: 10px;
}

.videoCutsActionListItem:hover {
  background-color: #3c3c3c;
  box-shadow: none;
  cursor: pointer;
}

.videoCutsActionListItem > p {
  width: 230px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.videoCutsPlayBtn {
  background: linear-gradient(
    224.52deg,
    rgba(180, 235, 84, 0.5) 18.39%,
    rgba(180, 235, 84, 0.25) 63.4%,
    rgba(255, 255, 255, 0) 96.95%,
    rgba(255, 255, 255, 0) 96.95%
  );
  border: 1px solid #b4eb54;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoCutsPlayBtn:hover {
  background: #b4eb548e;
}

.videoCutsPlayBtn > svg {
  width: 13px;
  height: 13px;
}

@media screen and (max-width: 1700px) {
  .videoCutsActionList {
    max-height: 67vh;
    min-height: 67vh;
  }
}

.spinn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checked {
  opacity: 1;
  pointer-events: auto;
}
.unchecked {
  opacity: 0.3;
  pointer-events: none;
}