.videoCutsBackBtn {
    background: #171F2F;
    border-radius: 0px 0px 10px 0px;
    border: none;
}

.videoCutsBackBtn:hover {
    background: #0f1520;
    cursor: pointer;
}

.videoCutsBackBtn>p {
    text-transform: uppercase;
    
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    transform: rotate(270deg);
}