.headerContainer {
  width: 100%;
  height: 40px;
  background-color: var(--main-gray);
  display: flex;
  justify-content: center;
}

.headerSeason {
  font-size: 24px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.headerGameWeek {
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-midnight-blue);
  padding-right: 20px;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.headerSelect {
  background: #e5e5e5;
  border: 0.1px solid #000000;
  border-radius: 5px;
  height: 30px;
  width: 133px;

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
}

.tableWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.tableContainer {
  color: #fff;
  background: #0f1521;
  border-radius: 0px 10px 10px 0px;
  height: 100vh;
  overflow-x: scroll;
}

.fixDate {
  position: sticky;
  left: 0px;
  background-color: var(--main-navy-blue);
}

.fixOpponent {
  position: sticky;
  left: 100px;
  background-color: var(--main-navy-blue);
}

.fixScore {
  position: sticky;
  left: 300px;
  background-color: var(--main-navy-blue);
}

.matchesTable {
  text-align: center;
  width: 100%;
  font-size: 16px;
  table-layout: fixed;
}

.matchesTable thead {
  font-size: 17px;
  line-height: 41px;
  text-transform: uppercase;
  background-color: var(--main-navy-blue);
  position: sticky;
  top: 0;
  z-index: 1;
}

.matchesTable thead > tr > th {
  font-weight: 400;
}

.matchesTable thead tr th:nth-child(n) {
  width: 40px;
}

.matchesTable thead tr th:nth-child(1) {
  width: 100px;
}

.matchesTable thead tr th:nth-child(2) {
  width: 200px;
}

.matchesTable thead tr th:nth-child(3) {
  width: 100px;
}

.matchesTable tbody {
  vertical-align: baseline;
}

.matchesTable tbody tr {
  background-color: var(--main-gray);
  vertical-align: middle;
  height: 60px;
}
.itemLink {
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* .matchesTable tbody tr td:hover {
    box-shadow: inset 1px 1px 13px 2px #1774ff;
    cursor: pointer;
  } */

.tableActionBtns {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #fff;
  position: absolute;
  right: 77px;
  top: -50px;
}

.tableActionBtns > button {
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  background: rgba(180, 235, 84, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 10px 10px 0px 0px;
  height: 50px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
}

.tableActionBtns button.activeBtn {
  z-index: 2;
}

.tableActionBtns button.disableBtn {
  background-color: grey;
}

.matchSelector {
  background: #0f1521;
  border: 1px solid #b4eb54;
  /* box-shadow: inset 0px 4px 19px -2px #1774ff; */
  border-radius: 0 0 10px 10px;
  height: 135px;
  width: 465px;
  position: absolute;
  right: 410px;
  top: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parametrsSelector {
  background: #0f1521;
  border: 1px solid #b4eb54;
  /* box-shadow: inset 0px 4px 19px -2px #1774ff; */
  border-radius: 0 10px 10px 10px;
  height: calc(100% - 100px);
  width: calc(100% - 70px);
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px;
  grid-gap: 20px;
  overflow-y: auto;
}
.parametrsColumn {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.title {
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.parametrsSelector > div {
  color: #fff;
  display: flex;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  align-items: center;
  gap: 10px;
  max-width: 200px;
}
.btnConfirm {
  font-size: 24px;
  font-weight: 600;
  width: 150px;
  position: fixed;
  bottom: 70px;
  right: 60px;
  cursor: pointer;
  padding: 10px 0;
  background-color: transparent;
  border: 1px solid var(--main-whitesmoke);
  color: var(--main-whitesmoke);
  text-transform: uppercase;
}

@media screen and (max-width: 1500px) {
  .parametrsSelector {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(10, 1fr); */
  }

  .tableActionBtns {
    right: 35px;
    top: -50px;
  }

  .tableActionBtns > div {
    height: 40px;
    width: 170px;
  }
}
