.modalOverlay {
    height: calc(100vh * var(--scale-multiplier));
    width: calc(100vw * var(--scale-multiplier));
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 999;
    color: white;
}

.modal {
    border-radius: 12px;
    background-color: #0F1521;
    min-width: 300px;
    min-height: 200px;
}

.modal > header {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
}

.modalCloseBtn {
    margin-left: auto;
    width: 34px;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
}

.modalCloseBtn:hover {
    cursor: pointer;
    background-color: #ffffff21;
}

.modalContent {
    padding: 0px 20px;
}