.teamInfoContainer {
  background-color: var(--main-navy-blue);
  padding: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: var(--main-whitesmoke);
  width: 240px;
}

.teamInfoContainer img {
  width: 236px;
  height: 240px;
  object-fit: contain;
}

.playerLogos {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
}

.playerLogos img:first-child {
  width: 136px;
  height: 90px;
  object-fit: contain;
  aspect-ratio: 3/2;
  border-bottom: 1px solid white;
  padding-bottom: 2px;
}

.playerLogos img:nth-child(2) {
  width: 136px;
  height: 160px;
  object-fit: contain;
}

.teamInfoContainer h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin: 5px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 41px;
}

.teamInfoContainer h3 > span {
  text-wrap: nowrap;
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.teamInfoContainer h3::before {
  content: '';
  background: grey;
  height: 1px;
  width: 50%;
  margin: 10px 0;
}

.teamInfoContainer h4 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.teamInfoContainer ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 650px);
  overflow-y: auto;
}

.teamInfoContainer ul > li {
  max-width: 260px;
}

li.underline {
  border-bottom: 1px solid #fff;
}
.favWrapper {
  position: relative;
  margin-top: -25px;
  width: 240px;
  display: flex;
  flex-direction: column;
}
.favoritesIcon {
  align-self: flex-end;
  position: relative;
  z-index: 1;
  top: 25px;
  right: -5px;
  cursor: pointer;
}
.tooltip {
  position: absolute;
  z-index: 0;
  border: 1px solid white;
  padding: 13px 30px;
  border-radius: 9px;
  height: 0;
  opacity: 0; /* Изначально элемент полностью прозрачен */
  visibility: hidden; /* Изначально элемент невидим */
  transform: translateY(-10px); /* Изначально элемент сдвинут вверх на 10px */
  transition: opacity 0.3s ease, visibility 0s, transform 0.5s ease;
  background-color: black;
  top: 50px;
}
.favoritesIcon:hover + .tooltip {
  opacity: 1; /* Элемент непрозрачен */
  visibility: visible; /* Элемент видим */
  transform: translateY(0);
  height: inherit;
}
.tooltip:hover {
  opacity: 1; /* Элемент непрозрачен */
  visibility: visible; /* Элемент видим */
  transform: translateY(0);
  height: inherit;
}
