.errorPageTextContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.errorPageTextContainer h3 {
    color: #fff;
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    padding: 0 20px;
    max-width: 768px;
    margin: auto;
}

.logo {
    background-image: url(../../../public/images/logo.png);
    width: 70vw;
    margin: auto;
    height: 300px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}