.itemWrapper {
  position: relative;
}
.item {
  text-align: center;
  border-bottom: 5px solid #fff;
  padding: 10px 10px 0 10px;
}

.teamName {
  text-align: center;
  font-weight: 500;
  margin: 10px 0;
}
.title {
  text-align: center;
}

.btns {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  padding: 0 10px;
}

.btns button {
  width: 90px;
  height: 50px;
  background: #e2e2e2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset;
  border: none;
  cursor: pointer;
  color: black;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.btns button:first-child {
  border-top-left-radius: 10px;
}

.btns button:last-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.btns button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.hr {
  height: 6px;
  background-color: #444750;
  margin: 0;
}

.playBtn {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 16px;
  top: 9px;
  z-index: 2;
  cursor: pointer;
  color: black;
}
