.ProfileMenu__button-video {
    
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    color: #0F1521;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    margin-right: 60px;
}

.ProfileMenu__button-video:hover {
    cursor: pointer;
}