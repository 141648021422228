.recentlyVisitedListSwitcher {
    display: flex;
    flex-direction: column;
}

.recentlyVisitedListSwitcher>b {
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: var(--main-whitesmoke);
}

.switchCheckbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}

.switch {
    cursor: pointer;
    max-width: 360px;
    width: 100%;
    height: 45px;
    background: transparent;
    border: 1px solid var(--main-whitesmoke);
    border-radius: 10px;
    position: relative;
    color: var(--main-whitesmoke);
    transition: background-color 0.2s;
    margin-top: 10px;
}

.switch .switchButton {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    height: 41px;
    transition: 0.2s;
    background: var(--main-whitesmoke);
    width: 91px;
    border-radius: 10px;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    display: flex;
    align-items: center;
    justify-content: center;
}


.switchCheckbox:checked+.switchButton {
    left: calc(100% - 2px);
    transform: translateX(-100%);
    background: #CCE4F1;
}

.switchCheckbox:active+.switchButton {
    width: 60px;
}

.switchCheckbox:checked~.switchLabels {
    background: var(--main-whitesmoke);
    border: 1px solid var(--main-whitesmoke);
    color: var(--main-navy-blue);
    border-radius: 10px;
}

.switchCheckbox:checked~.switchButton>svg>path {
    stroke: var(--main-navy-blue);
}

.switchLabels {
    display: relative;
    z-index: 0;
    height: 100%;
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.switchLabels>span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.switchLabels>span:first-child {
    left: 0;
}

.switchLabels>span:last-child {
    right: 0;
}