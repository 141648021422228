.PlayerStatisticks__table-body-index {
    
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 34px;
    border-bottom: 1px solid black;
    width: 180px;
    display: flex;
    justify-content: center;
}