.profilePageContent {
    height: calc((100vh - 89px) * var(--scale-multiplier));
    display: flex;
}

.profilePageMainSettings {
    padding: 30px;
    background: var(--main-navy-blue);
    /* width: calc(25vw * var(--scale-multiplier)); */
    width: auto;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: auto;
}

.profilePageRecentlyVisitedList {
    background-color: var(--main-gray);
    padding: 35px 20px 25px 20px;
    width: calc(75vw * var(--scale-multiplier));
    border-top-right-radius: 10px;
}

.profilePageRecentlyVisitedListContent {
    background: var(--main-navy-blue);
    border-radius: 10px;
    height: 98%;
    padding: 10px 12px;
}

.profilePageRecentlyVisitedListHidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--main-navy-blue);
    border-radius: 10px;
    height: 100%;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
}

.profilePageRecentlyVisitedListShow {
    padding: 40px;
    color: #fff;
}

.profilePageRecentlyVisitedListShow>h3 {

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 2px solid #FFFFFF;
}

.profilePageRecentlyVisitedListShow>ul {
    list-style: none;
    padding: 0;
    margin: 0;

    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    height: calc((100vh - 265px) * var(--scale-multiplier));
    overflow-y: auto;
}

.profilePageRecentlyVisitedListShow>ul>li {
    margin-top: 5px;
    padding: 10px 60px 10px 10px;
    transition: all .2s ease-in-out;
}

.profilePageRecentlyVisitedListShow>ul>li:hover {
    background: #CCE4F1;
    border-radius: 5px;
    cursor: pointer;
}

.profilePageRecentlyVisitedListShow>ul>li>a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
}

.profilePageRecentlyVisitedListShow>ul>li:hover>a {
    color: var(--main-navy-blue);
}


/* change language styles */
.profilePageChangeLanguage>b {

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
}

.profilePageChangeLanguage>div {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}

.profilePageChangeLanguage>div>button {
    border: 1px solid #2060E1;
    border-radius: 10px;
    width: 170px;
    height: 45px;
    color: #fff;
    background: transparent;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.profilePageChangeLanguage>div>button:hover {
    background: #B4EB54;
    border: 1px solid #B4EB54;
    color: #000;
}

.profilePageChangeLanguage>div>button.activeChangeLanguageBtn {
    background: #0F1521;
    border: 1px solid #B4EB54;
    box-shadow: inset 32px 17px 34px -11px #B4EB54;
    transition: all .2s ease-in-out;
}

.profilePageChangeLanguage>div>button.activeChangeLanguageBtn:hover {
    background: #B4EB54;
    color: #000;
}

/* change email styles */
.changeEmailAddress>b {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
}

.changeEmailAddress>input {
    margin-top: 10px;
    max-width: 340px;
    width: 95%;
    height: 45px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    border: none;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    padding: 0 10px;
}

/* manager info styles */

.contactManagerInfo>b {

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
}

.contactManagerInfo>div {
    display: flex;
    align-items: center;
    color: #fff;

    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    gap: 10px;
    margin-top: 10px;
}

.contactManagerInfo>div>img {
    width: 180px;
}

.contactManagerInfo>div>div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}