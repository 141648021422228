.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  background-color: var(--main-whitesmoke);
  padding: 5px 15px;
  font-size: 20px;
  color: black;
  line-height: 1.2;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  text-decoration: underline;
}
.players {
  display: flex;
  flex-direction: column;
}
.playersHeading {
  background-color: #6886bd66;
  padding: 5px 50px;
  line-height: 1.3;
  display: flex;
  align-items: center;
}
.row {
  padding: 5px 50px 5px 15px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #6886bd;
  background-color: var(--main-sky-blue);
  color: black;
}
.rowLeftStats,
.rowRightStats {
  display: flex;
  flex: 1;
  display: flex;
  gap: 15px;
  font-size: 14px;
  align-items: center;
}
.rowLeftStats a:hover {
    text-decoration: underline;
}
.leftStats,
.rightStats,
.rowLeftStats,
.rowRightStats {
  flex: 1;
  display: flex;
  gap: 15px;
  font-size: 14px;
  /* font-weight: 600; */
  align-items: center;
}
.rightStats,
.rowRightStats {
  list-style: none;
  margin: 0;
}
.rightStats li,
.rowRightStats li {
  flex: 1;
}
.statsWrapper {
  display: flex;
  position: relative;
  background-color: #1B2436;
}
.teamName {
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0; 
}

.text {
    writing-mode: vertical-rl; 
    transform: rotate(180deg);
    letter-spacing: 2px; 
    font-size: 16px;
    margin: auto;
    font-weight: 600;
  }
  .modalGear {
    height: 100%;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.528);
    z-index: 100;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }