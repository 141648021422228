.teamInfoContainer {
  background-color: var(--main-navy-blue);
  padding: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: var(--main-whitesmoke);
  width: 258px;
}

.teamInfoContainer img {
  width: 258px;
  height: 200px;
  object-fit: contain;
}

.teamInfoContainer h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin: 5px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 41px;
  cursor: pointer;
}

.teamInfoContainer h3::before {
  content: '';
  background: grey;
  height: 1px;
  width: 100%;
  margin: 5px 0;
}

.teamInfoContainer h3::after {
  content: '';
  background: grey;
  height: 1px;
  width: 50%;
}

.teamInfoContainerLink {
  text-decoration: none;
  color: white;
}

.teamInfoContainer h4 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.teamInfoContainer ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}

.teamInfoContainer ul > li {
  max-width: 260px;
}

li.underline {
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 1500px) {
  .teamInfoContainer ul > li {
    font-size: 14px;
  }
}
