.competitions {
    background-color: #0F1521;
}

.searchInput {
    background-color:  var(--main-navy-blue);
    height: 60px;
    min-width: 200px;
    /* border: 1px solid #1774FF; */
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    width: 80%;
}

.searchInput::placeholder {
    color: white;
    text-align: center;
}

.leftButtonContainer {
    position: absolute;
    right: 105px;
    top: 0px;
}

.competitionsButton {
    width: 50px;
    height: 60vh;
    background: #2B74E1;
    color: white;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.competitionsButton span {
    display: inline-block;
    transform: rotate(270deg);
}

.rightButtonsContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    right: 21px;
    gap: 21px
}

.exitButton {
    width: 50px;
    height: 35vh;
    background: #F51A32;
    color: white;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border: none;
    cursor: pointer;
}

.exitButton span {
    display: inline-block;
    transform: rotate(270deg);
    white-space: nowrap
}

.myProfileButton {
    width: 50px;
    height: 55vh;
    background: #B4EB54;
    color: black;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border: none;
    cursor: pointer;
}

.myProfileButton span {
    display: inline-block;
    transform: rotate(270deg);
    white-space: nowrap
}

.competitionsContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 25px;
    max-height: calc((100vh - 100px) * var(--scale-multiplier));
    overflow-y: auto;
    background-color: black;
}
.spinnWrap {
    background: rgb(23, 31, 47);
    padding: 20;
    text-align: center;
    width: 100%;
}