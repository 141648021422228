.addPlayListModal {
  background-color: #0f1521;
  border: 1px solid #b4eb54;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 12px 10px;
  width: 328px;
}

.addPlayListModal label {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  display: flex;
  text-transform: capitalize;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 10px;
}

.addPlayListModal input,
select {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  border-radius: 10px;
  height: 35px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.addPlayListModal button {
  background: #ffffff;
  border-radius: 10px;
  height: 35px;
  width: 140px;
  text-align: center;
  align-self: center;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;

  color: #0f1521;
}
