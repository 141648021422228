.tablePlayersListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  background-color: var(--main-gray);
  cursor: pointer;
  opacity: 0.7;
  color: var(--main-midnight-blue);
  text-decoration: none;
}

.tablePlayersListItem > div {
  width: 33%;
}

.tablePlayersListItem > div:nth-child(2) {
  text-align: center;
}

.tablePlayersListItem > div:last-child {
  text-align: center;
}

.tablePlayersListItem:hover {
  opacity: 1;
  background-color: var(--main-light-blue);
  color: #000;
  /* box-shadow: inset -2px 1px 32px -2px #1774FF; */
}

.playerName {
  display: flex;
  align-items: center;
}

.playerName p {
  margin-left: 10px;
}

.tablePlayersListItem span {
  font-weight: 500;
  margin-left: 10px;
}

.playerFlag {
  object-fit: contain;
  height: 60px;
  width: 40px;
  aspect-ratio: 16/9;
  padding: 0 5px;
}

.playerImg {
  width: 40px;
  height: 60px;
  object-fit: cover;
}
