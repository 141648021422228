.wrapperTimeline {
    position: relative;
    font-size: 18px;
    text-align: center;
    width: 97%;
    margin: 15px;
    background-color: #3A4C6C;
    border-radius: 10px;
    padding: 0 15px 15px 15px;
    z-index: 1;
}
.nodata {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.30);
    transform: translateY(-50%);
    z-index: 3;

}