.videoCutsPanel {
    background: #0F1521;
    border-radius: 10px;
    min-width: 350px;
    height: fit-content;
    margin-right: 30px;
    z-index: 1;
}

.videoCutsFooterInfoContainer {
    display: flex;
    align-items: center;
    padding: 14px 20px;
    color: #B4EB54;
    
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    /* justify-content: center; */
    gap: 50px;
}

.videoCutsFooterPlayCircleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.videoCutsFooterPlayCircle {
    width: 18px;
    height: 18px;
    background: linear-gradient(224.52deg, rgba(180, 235, 84, 0.5) 18.39%, rgba(180, 235, 84, 0.25) 63.4%, rgba(255, 255, 255, 0) 96.95%, rgba(255, 255, 255, 0) 96.95%);
    border: 1px solid #B4EB54;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}