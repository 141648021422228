.pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 8px;
}
.pagination li a {
    font-size: 16px;
    font-weight: 700;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #171F2F;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 0.1px 1.5px rgba(255, 255, 255, 0.39);
}
.pagination li.active a {
    background-color: #F5F5F5;
    /* border: 1px solid white; */
    color: #171F2F;
}
.pagination li.disable a {
    cursor: not-allowed;
    background-color: gray;
}