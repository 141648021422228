
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh * var(--scale-multiplier));
  background: var(--main-whitesmoke);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}

.containerLight {
  background: linear-gradient(197.98deg,
      #ebf1ff 4.7%,
      #eedfb7 67.39%,
      #ead399 112.4%);
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 213px;
  width: 100%;
  max-width: 1372px;
}

.loginFormContainer>span {
  color: var(--main-navy-blue);
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
  margin-left: 10px;
  max-width: 230px;
}

.logo {
  background-image: url(../../../public/images/newlogo.png);
  width: 160px;
  height: 80px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 30px;
  left: 40px;
}

  .rectangles {
    background-image: url(../../../public/images/rectangles.png);
    height: 787px;
    width: 479px;
    position: absolute;
    top: 295px;
    right: -29px;
  }

.buttonContainer {
  position: absolute;
  right: 0px;
  top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.competitionsButton span {
  display: inline-block;
  white-space: nowrap;
  font-size: 24px;
}

.competitionsButton {
  width: 230px;
  height: 50px;
  background: var(--main-navy-blue);
  color: var(--main-whitesmoke);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}

.competitionsButtonLight {
  background: #0F1521;
}

.myProfileButton span {
  display: inline-block;
  /* transform: rotate(270deg); */
  white-space: nowrap;
}

.myProfileButton {
  width: 230px;
  height: 50px;
  background: var(--main-light-blue);
  color: var(--main-navy-blue);

  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.myProfileButtonLight {
  background: #ead399;
}