.Matches__setting-button {
    
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    color: #FFFFFF;
    background: #171F2F;
    box-shadow: inset 0px 4px 19px -2px #1774ff;
    border-radius: 10px 10px 0px 0px;
    width: 197px;
}
    