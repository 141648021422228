.videoCutsActionBtnsContainer {
    display: flex;
    gap: 2px;
}

.videoCutsActionBtnsContainer>button {
    background: #0F1521;
    color: #fff;
    
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: 53px;
    border: none;
    width: 50%;
}

.videoCutsActionBtnsContainer>button:hover {
    background: #0a0e16;
    cursor: pointer;
}

.videoCutsActionBtnsContainer>button:first-child {
    box-shadow: inset -6px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px 0px 0px 0px;
}

.videoCutsActionBtnsContainer>button:last-child {
    box-shadow: inset 6px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 0px 0px;
}

