.contentCard {
  border-radius: 0 10px 10px 0px;
  width: 100%;
  height: calc((100vh - 110px) * var(--scale-multiplier));
  align-self: end;
  position: relative;
  padding: 0px 20px 20px 0;
  /* background: linear-gradient(
    184.14deg,
    #5c5c5c 2.14%,
    rgba(242, 242, 242, 0.536458) 48.82%,
    rgba(197, 162, 110, 0) 102.84%
  ); */
  /* overflow: hidden; */
}

.contentPage {
  width: 100%;
  /* background-color: #fff; */
  height: calc((100vh - 120px) * var(--scale-multiplier));
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
}
