.playlistItemPageHeader {
  background: var(--main-navy-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.playlistItemPageHeader > div {
  display: flex;
  align-items: center;
  gap: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--main-whitesmoke);
  cursor: pointer;
}

.playlistItemPageHeader > h3 {
  width: 100%;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  color: #fff;
}
