.pageContainer {
    width: 100%;
    height: 100%;
    background-color: #0F1521;
    color: #fff;

    position: relative;
}

.pageTabs {
    font-weight: 500;
    font-size: 17px;
    position: absolute;
    display: flex;
    gap: 3px;
    color: #fff;
    right: 30px;
    top: -35px;
}

.pageTabs>div,
.pageTabs>a {
    background: #0F1521;
    border: 1px solid #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 50px;
    font-size: 15px;
    cursor: pointer;
}

.contentContainer {
    display: flex;
}

.contentContainer>header {
    width: 50px;
    height: calc((100vh - 200px) * var(--scale-multiplier));
    box-shadow: 9px 0px 5px rgba(0, 0, 0, 0.7);
    position: relative;
}

.contentContainer>header>span {
    position: absolute;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(270deg);
    width: 200px;
    left: -73px;
    top: 50vh;
}

.playerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 0 auto;
}

@media screen and (max-width: 1500px) {
    .pageTabs>div,
    .pageTabs>a {
        width: 130px;
        height: 35px;
        font-size: 15px;
    }

    .pageTabs {
        right: 30px;
        top: -37px;
    }
}