.statistic {
  display: none;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  height: 100%;
  background-color: var(--main-whitesmoke);
  border-radius: 0 10px 10px 0;
}

.statistic.show {
  display: block;
  opacity: 1;
}

.statheader {
  padding: 15px 0px 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border-top-right-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.statheader > span {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

.listStats {
  overflow-y: auto;
  height: calc((100vh - 200px) * var(--scale-multiplier));
  min-width: 366px;
}

.listStats b {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.listStatsItem {
  padding-left: 10px;
}

.statTitle {
  font-weight: 500;
  font-size: 17px;
  line-height: 34px;
  text-transform: capitalize;
  margin: 20px 0 10px 0;
}

.listStatsItemStat {
  font-weight: 300;
  font-size: 15px;
  line-height: 34px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  border-bottom: 1px solid #000;
}

.listStatsItemStat > span:last-child {
  padding-right: 20px;
}

.playerSeasonSelect {
  border: 0.1px solid #000000;
  border-radius: 5px;
  height: 30px;
  width: 133px;

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}
