.pitchContainer {
    position: relative;
}

.pitchFooter {
    bottom: -50px;
    display: flex;
    position: absolute;
    gap: 100px;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    justify-content: center;
}

.matchTime {
    display: flex;
    gap: 10px;
    align-items: center;
}

.matchTime svg {
    fill: #fff;
}
