.tableWrapper {
  position: relative;
  width: 100%;
}

.tableContainer {
  width: 100%;
  align-self: start;
  color: var(--main-midnight-blue);
  background: #0f1521;
  border-radius: 0px 10px 10px 0px;
  height: calc((100vh - 120px) * var(--scale-multiplier));
  overflow-x: auto;
}

.fixDate {
  position: sticky;
  left: 0px;
  background-color: var(--main-gray);
}

.fixOpponent {
  position: sticky;
  left: 100px;
  background-color: var(--main-gray);
}

.fixScore {
  position: sticky;
  left: 300px;
  background-color: var(--main-gray);
}

.matchesTable {
  text-align: center;
  min-width: 100%;
  max-width: max-content;
  width: max-content;
  font-size: 16px;
  table-layout: fixed;
}

.matchesTable thead {
  font-size: 17px;
  line-height: 41px;
  text-transform: uppercase;
  background-color: var(--main-gray);
  position: sticky;
  top: 0;
  z-index: 1;
}

.matchesTable thead > tr > th {
  font-weight: 400;
}

.matchesTable thead tr th:nth-child(n) {
  /* width: 40px; */
  padding: 0 5px;
  font-weight: 500;
}

.matchesTable thead tr th:nth-child(1) {
  width: 100px;
}

.matchesTable thead tr th:nth-child(2) {
  width: 200px;
}

.matchesTable thead tr th:nth-child(3) {
  width: 100px;
}

.matchesTable tbody {
  vertical-align: baseline;
}

.matchesTable tbody tr {
  background-color: var(--main-gray);
  vertical-align: middle;
  height: 60px;
}

.itemLink {
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* .matchesTable tbody tr td:hover {
  box-shadow: inset 1px 1px 13px 2px #1774ff;
  cursor: pointer;
} */

.tableActionBtns {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #fff;
  position: absolute;
  right: 77px;
  top: -50px;
}

.tableActionBtns > button {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  background: var(--main-navy-blue);
  backdrop-filter: blur(2px);
  border-radius: 10px 10px 0px 0px;
  height: 50px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-whitesmoke);
  border: none;
}
/* XML buttons */
.btnXML {
  position: relative;
  z-index: 10;
}
.modalXML{
  width: max-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 15;
  bottom: -120px;
  left: 0;
  background-color: rgba(180, 235, 84, 1);
  color: black;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0px 1px 15px rgba(255, 255, 255, 0.526); */
}
.modalXML button:first-child {
  margin-bottom: 15px;
}
.modalXML button {
  color: black;
  font-size: 18px;
  cursor: pointer;
  background-color: inherit;
  margin: 0;
  border: 1px solid black;
  padding: 5px 15px;
  transition: box-shadow 0.3s ease-in-out;
}
.modalXML button:hover {
  /* text-decoration: underline; */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.116);
}

.tableActionBtns button.activeBtn {
  z-index: 2;
}

.tableActionBtns button.disableBtn {
  background-color: grey;
}

.matchSelector {
  border-radius: 10px 10px 0 0;
  border: 1px solid #B4EB54;
  background: #0F1521;
  box-shadow: 0px 4px 19px -2px #B4EB54 inset;
  width: 900px;
  max-height: 730px;
  position: absolute;
  right: 538px;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.parametrsSelector {
  position: relative;
  background: #0f1521;
  border: 1px solid var(--main-sky-blue);
  /* box-shadow: inset 0px 4px 19px -2px #1774ff; */
  border-radius: 0 10px 10px 10px;
  height: calc(100% - 100px);
  width: calc(100% - 60px);
  position: absolute;
  /* right: 30px; */
  top: 0;
  z-index: 2;
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px;
  grid-gap: 20px; */
  display: flex;
  gap: 50px;
  padding: 20px 40px 20px 20px;
  overflow-y: auto;
}

.parametrsSelector>div {
  color: #fff;
  display: flex;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  /* max-width: 200px; */
}

.parametrsColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
.flex {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.btnConfirm {
  position: fixed;
  bottom: 100px;
  right: 50vw;
  cursor: pointer;
  background-color: var(--main-sky-blue);
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 500;
}

@media screen and (max-width: 1500px) {
  .parametrsSelector {
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(10, 1fr); */
  }

  .tableActionBtns {
    right: 35px;
    top: -50px;
  }

  .tableActionBtns > div {
    height: 40px;
    width: 170px;
  }
}

.matchOptionsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #FFF;
  overflow-y: auto;
  background: #0F1521;
  box-shadow: 0 4px 19px -2px #B4EB54 inset;
}

.matchOption {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 58px;
  line-height: 58px;
  padding-left: 40px;
}

.team_matchesSettings {
  position: relative !important;
  border: none !important;
  background: none !important;
  max-width: 100% !important;
}
