.MatchesData__info-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 100%;
    height: 58px;
}