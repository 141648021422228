.contentContainer {
    display: flex;
}

.contentContainer>header {
    width: 50px;
    height: calc((100vh - 200px) * var(--scale-multiplier));
    box-shadow: 9px 0px 5px rgba(0, 0, 0, 0.7);
    position: relative;
}

.contentContainer>header>span {
    position: absolute;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(270deg);
    width: 200px;
    left: -73px;
    top: 50vh;
}

.playerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 0 auto;
}