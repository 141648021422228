.tableWrapper {
  position: relative;
  width: 100%;
}

.tableContainer {
  width: 100%;
  align-self: start;
  color: var(--main-midnight-blue);
  background: #0f1521;
  border-radius: 0px 10px 10px 0px;
  height: calc((100vh - 120px) * var(--scale-multiplier));
  overflow-x: auto;
}

.fix {
  position: sticky;
  left: 0px;
  background-color: var(--main-gray);
}

.matchesTable {
  text-align: center;
  min-width: 100%;
  max-width: max-content;
  width: max-content;
  font-size: 16px;
  table-layout: fixed;
}

.matchesTable thead {
  font-size: 17px;
  line-height: 41px;
  text-transform: uppercase;
  background-color: var(--main-gray);
  position: sticky;
  top: 0;
  z-index: 1;
}

.matchesTable thead tr th:nth-child(n) {
  padding: 0 5px;
  font-weight: 500;
  /* width: 40px; */
}

.matchesTable thead tr th:nth-child(1) {
  width: 600px;
}

.matchesTable thead tr th:nth-child(2) {
  width: 200px;
}

.matchesTable thead>tr>th {
  font-weight: 400;
}

.matchesTable tbody {
  vertical-align: baseline;
}

.matchesTable tbody tr {
  background-color: var(--main-gray);
  vertical-align: middle;
  height: 60px;
}

.matchesTable tbody tr td:hover {
  /* box-shadow: inset 1px 1px 13px 2px #1774ff; */
  cursor: pointer;
}

.tableActionBtns {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--main-whitesmoke);
  position: absolute;
  right: 77px;
  top: -50px;
}

.tableActionBtns>div {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  background: var(--main-navy-blue);
  backdrop-filter: blur(2px);
  border-radius: 10px 10px 0px 0px;
  height: 50px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableActionBtns div.activeBtn {
  z-index: 2;
}

.matchSelector {
  background: #0f1521;
  border: 1px solid #b4eb54;
  /* box-shadow: inset 0px 4px 19px -2px #1774ff; */
  border-radius: 0 0 10px 10px;
  height: 135px;
  width: 465px;
  position: absolute;
  right: 410px;
  top: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parametrsSelector {
  background: #0f1521;
  border: 1px solid var(--main-sky-blue); 
  /* box-shadow: inset 0px 4px 19px -2px #1774ff; */
  border-radius: 0 10px 10px 10px;
  height: calc(100% - 100px);
  width: calc(100% - 60px);
  position: absolute;
  /* right: 30px; */
  top: 0;
  z-index: 2;
  /* display: grid; */
  /* grid-auto-flow: column; */
  /* grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 50px; */
  display: flex;
  gap: 50px;
  padding: 20px 40px 20px 20px;
  /* grid-gap: 20px; */
  overflow-y: auto;
}

.parametrsSelector>div {
  color: #fff;
  display: flex;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  /* max-width: 200px; */
}

.btnConfirm {
  position: fixed;
  bottom: 100px;
  right: 50vw;
  cursor: pointer;
  background-color: var(--main-sky-blue);
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 500;
}

@media screen and (max-width: 1500px) {
  .parametrsSelector {
    /* grid-auto-flow: row;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(10, 1fr); */
  }
}

@media screen and (max-width: 1200px) {
  .parametrsSelector {
    /* grid-template-columns: repeat(3, 1fr); */
  }
}