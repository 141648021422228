.MatchesPopup {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s ease-out, opacity 0.5s ease-out;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    position: relative;
}