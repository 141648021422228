.playlistItemPageMainContainer {
  display: flex;
  gap: 20px;
  background-color: var(--main-midnight-blue);
  height: calc((100vh - 208px) * var(--scale-multiplier));
  overflow-y: auto;
  padding: 20px;
}

.playListItemPageEventsCard {
  background: var(--main-gray);
  border-radius: 10px;
  height: fit-content;
  max-height: 100vh;
  padding: 20px 0;
  color: var(--main-midnight-blue);
  max-height: calc((100vh - 253px) * var(--scale-multiplier));
  overflow-y: auto;
}

.playListItemPageEventsCard > div {
  padding: 15px;
}

.playListItemPageEventsCard > div:hover {
  background: #e9f8ff;
  /* box-shadow: inset -1px 1px 12px 3px rgba(23, 116, 255, 0.7); */
  cursor: pointer;
}

.playListItemPageEventsCard > b {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.playListItemPageEventsCard > div > span {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playListItemPageEventsCard > div > svg {
  width: 25px;
  fill: var(--main-midnight-blue);
}

.playListItemPageEventsCard > div {
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  gap: 10px;
}

.centeredContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
