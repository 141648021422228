.pageContainer {
  width: 100%;
  height: 100%;
  background-color: #0f1521;
  color: #fff;
  position: relative;
}

.pageTabs {
  font-weight: 500;
  font-size: 17px;
  position: absolute;
  display: flex;
  gap: 3px;
  color: #fff;
  right: 30px;
  top: -35px;
}

.pageTabs>div,
.pageTabs>a {
  background: #0f1521;
  border: 1px solid #ffffff;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 35px;
  font-size: 15px;
  cursor: pointer;
}

.contentContainer {
  display: flex;
}

.contentContainer>header {
  width: 50px;
  height: calc(100vh - 200px);
  box-shadow: 9px 0px 5px rgba(0, 0, 0, 0.7);
  position: relative;
}

.contentContainer>header>span {
  position: absolute;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(270deg);
  width: 200px;
  left: -73px;
  top: calc(100vh - 85%);
}

.pitchWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pitchContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.teamTape {
  position: relative;
}

.teamTape>img {
  position: absolute;
  width: 104px;
  height: 120px;
  object-fit: contain;
  z-index: 1;
}

.logoHome {
  left: -41px;
  top: -21px;
}

.logoAway {
  left: -1px;
  top: -21px;
}

.teamTape>svg:nth-child(2) {
  display: block;
  height: calc((100vh - 320px) * var(--scale-multiplier));
}

.teamTape>svg:nth-child(3) {
  position: absolute;
  left: 3px;
  top: 0;
  display: block;
  height: calc((100vh - 340px) * var(--scale-multiplier));
}

.teamNameHome {
  position: absolute;
  top: 300px;
  font-weight: 600;
  font-size: 20px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%) rotate(270deg);
  white-space: nowrap;
}

.teamNameAway {
  position: absolute;
  top: 300px;
  font-weight: 600;
  font-size: 20px;
  line-height: 41px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  left: -22px;
  top: 50%;
  left: 44%;
  transform: translate(-50%, -50%) rotate(90deg);
  white-space: nowrap;
}

.pitchContainer {
  position: relative;
}

.pitchFooter {
  bottom: -50px;
  left: 0;
  display: flex;
  position: absolute;
  gap: 100px;
  font-weight: 400;
  font-size: 25px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  justify-content: center;
  user-select: none;
}

.matchTime {
  display: flex;
  gap: 10px;
  align-items: center;
}

.matchTime svg {
  fill: #fff;
  cursor: pointer;
}


@media screen and (max-width: 1500px) {
  .pageTabs>div,
  .pageTabs>a {
    width: 130px;
    height: 35px;
    font-size: 15px;
  }

  .pageTabs {
    right: 30px;
    top: -37px;
  }
}